@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --color-white: #ffffff;
  --color-main-background: #35404b;
  --color-primary-green: #aed432;
  --color-gray: #4d5660;
  --color-gray-light: #4e5661;
  --color-black: #000000;
  --color-red: #ff0000;
  --color-dark-gray: #2a323c;
}
.app {
  height: 100%;
}

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--color-main-background);
  font-family: 'Poppins', sans-serif;
}

.MuiTooltip-tooltip {
  background: #fff !important;
  color: #000 !important;
}
